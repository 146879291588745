/*
    It was completely rewriten to custom position
*/


L.Rrose = L.Popup.extend({

	margin: {
		left: 50,
		right: 10,
		top: 10,
		bottom: 10,
	},

	_initLayout: function () {
		const prefix = 'leaflet-rrose';

		// Popup
		const container = this._container = L.DomUtil.create('div', prefix + ' ' + this.options.className + ' leaflet-zoom-animated');

		// Content
		const wrapper = this._wrapper = L.DomUtil.create('div', prefix + '-content-wrapper', container);
		this._contentNode = L.DomUtil.create('div', prefix + '-content', wrapper);
		L.DomEvent.disableClickPropagation(wrapper);
		L.DomEvent.on(this._contentNode, 'mousewheel', L.DomEvent.stopPropagation);

		// Dot on pinpoint
		const dot = this._dot = L.DomUtil.create('div', prefix + '-dot', container);

		// Update position after map pan
		this._map.on("move", () => this._updatePosition());
	},

	_updatePosition: function () {
		if (!this._map) {
			return;
		}

		// get point position relative to Map containter
		const pointPos = this._map.latLngToLayerPoint(this._latlng);

		const bbMap  = this._map._container.getBoundingClientRect();

		// set max width/height
		this._contentNode.style.maxWidth = (bbMap.width - this.margin.left - this.margin.right - 40) + 'px';
		this._wrapper.style.maxWidth = (bbMap.width - this.margin.left - this.margin.right) + 'px';
		this._wrapper.style.maxHeight = (bbMap.height - this.margin.top - this.margin.bottom) + 'px';


		// set popup position to default
		this._setPosition(pointPos);

		const popupPos = new L.Point(pointPos.x, pointPos.y);
		if (this._isPopupInsideMap()) {
			return;
		}

		let bbPopup;

		// too much right
		bbPopup = this._container.getBoundingClientRect();
		if (bbPopup.right > bbMap.right - this.margin.right) {
			popupPos.x -= bbPopup.right-bbMap.right+this.margin.right;
			this._setPosition(popupPos);
			if (this._isPopupInsideMap()) {
				return;
			}
		}


		// too much left
		bbPopup = this._container.getBoundingClientRect();
		if (bbPopup.left < bbMap.left + this.margin.left) {
			popupPos.x -= bbPopup.left-bbMap.left-this.margin.left;
			this._setPosition(popupPos);
			if (this._isPopupInsideMap()) {
				return;
			}
		}

		// too much down => flip
		bbPopup = this._container.getBoundingClientRect();
		if (bbPopup.bottom > bbMap.bottom - this.margin.bottom) {
			popupPos.y -= bbPopup.height + 40;
			this._setPosition(popupPos);
			if (this._isPopupInsideMap()) {
				return;
			}
		}

		// too much up
		bbPopup = this._container.getBoundingClientRect();
		if (bbPopup.top < bbMap.top + this.margin.top) {
			popupPos.y -= bbPopup.top-bbMap.top-this.margin.top;
			this._setPosition(popupPos);
			if (this._isPopupInsideMap()) {
				return;
			}
		}

		// too much down
		bbPopup = this._container.getBoundingClientRect();
		if (bbPopup.bottom > bbMap.bottom - this.margin.bottom) {
			popupPos.y -= bbPopup.bottom-bbMap.bottom+this.margin.bottom;
			this._setPosition(popupPos);
			if (this._isPopupInsideMap()) {
				return;
			}
		}
	},

	_setPosition: function (popupPos) {
		// Set new position
		L.DomUtil.setPosition(this._container, popupPos);

		const pointPos = this._map.latLngToLayerPoint(this._latlng);

		// Set position of dot
		this._dot.style.left = `${pointPos.x - popupPos.x}px`;
		this._dot.style.top = `${pointPos.y - popupPos.y - 24}px`;

	},

	_isPopupInsideMap() {
		const bbMap  = this._map._container.getBoundingClientRect();
		const bbPopup  = this._container.getBoundingClientRect();

		return (bbPopup.top > bbMap.top + this.margin.top
			&& bbPopup.left > bbMap.left + this.margin.left
			&& bbPopup.bottom < bbMap.bottom - this.margin.bottom
			&& bbPopup.right < bbMap.right - this.margin.right
		);
	}


});
