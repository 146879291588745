import IExtension from '../../IExtension';
import { Naja } from 'naja';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import html2canvas from 'html2canvas';
import {Workbook} from "exceljs/index";


interface HTMLImageCloneElement extends HTMLImageElement {
    dataBtnElement: HTMLButtonElement;
    dataWorkbook: Workbook;
    dataImgDiv: HTMLDivElement;
    dataImgClone: HTMLImageElement;
    dataImageNumber: number;
    dataDivOptions: any;
    dataPostTitle: string;
}

export default class ExcelExportExtension implements IExtension {
    private canvasCounter = 0;
    private sheetNameMaxLength = 31;
    private sheets = [
        'negativ',
        'positiv',
        'neutral'
    ];

    initialize (naja: Naja): void {
        if ($('button[data-export-to-xlsx]').length > 0) {
            $('button[data-export-to-xlsx]').click((e) => {
                // show  spinner
                const btnElement = <HTMLButtonElement> e.currentTarget;
                if (btnElement.innerHTML.indexOf('spinner') === -1) {
                    this.addSpinner(btnElement);
                }

                // call export
                this.exportToXls(btnElement);
            });
        }
    }

    private addSpinner(btnELement: HTMLButtonElement): void {
        btnELement.innerHTML = '<span class="spinner-border spinner-border-sm"></span> ' + btnELement.innerHTML;
    }

    public exportToXls (btnElement: HTMLButtonElement): void {
        this.canvasCounter = 0;

        // Create Workbook
        const workbook = new ExcelJS.Workbook();
        const imagePromises = [];

        // Get base div with rated img
        $('div[data-component-image-rating-view]').each((i, divEl) => {
            imagePromises.push(this.processImagesBaseDivs(btnElement, workbook, divEl, i+1));
        });

        Promise.all(imagePromises).then((imageClones: HTMLImageCloneElement[]) => {
            imageClones
                .toSorted((a, b) => a.dataImageNumber - b.dataImageNumber)
                .forEach(imageClone => {
                    this.onLoadBackgroundImage(imageClone.dataBtnElement, imageClone.dataWorkbook, imageClone.dataImgDiv, imageClone.dataImgClone, imageClone.dataImageNumber, $('div[data-component-image-rating-view]').length, imageClone.dataDivOptions, imageClone.dataPostTitle);
                })
        });


    }

    private addRatingsToXlsx (worksheet, ratings): void {
        if (ratings.length > 0) {
            for (const index in ratings) {
                const row = parseInt(index) + 1;
                worksheet.getCell('A' + row).value = row + '.';
                worksheet.getCell('A' + row).alignment = { horizontal: 'left' };
                worksheet.getCell('B' + row).value = ratings[index].userName;
                worksheet.getCell('C' + row).value = ratings[index].note;
            }
        }
    }

    private addCanvasToXlsx (workbook, worksheet, canvas): void {
        // canvas to base64
        const canvasBase64 = canvas.toDataURL();

        // add heatmap to Workbook
        const workbookImage = workbook.addImage({
            base64: canvasBase64,
            extension: 'png'
        });

        // add image to sheet
        worksheet.addImage(workbookImage, {
            tl: { col: 7, row: 0 },
            ext: { width: canvas.width, height: canvas.height }
        });
    }

    private clear (container): void {
        $(container).remove();
    }

    private createImgRating (btnELement: HTMLButtonElement, workbook: Workbook, image, ratings, destinationContainer, currentImageNumber: number, sheetType: string, postTitle: string, importCallback): void {
        $(image).imgRating({
            icon: {
                borderColor: '#23426f',
                backgroundColor: '#337ab7',
                textColor: '#23426f'
            },
            beforeIconCreated: function (iconOptions, data) {
                iconOptions.html = '<div style="color:#fff;position:absolute;left:0;padding:5px;text-align:center;width:100%;">' + data.index + '</div>';
                if (data.rating > 0) {
                    iconOptions.borderColor = '#3c763d';
                    iconOptions.backgroundColor = '#69AA46';
                    iconOptions.textColor = '#3c763d';
                } else if (data.rating < 0) {
                    iconOptions.borderColor = '#a94442';
                    iconOptions.backgroundColor = '#d74543';
                    iconOptions.textColor = '#a94442';
                }
            },
            onImport: function (data) {
                const heatmapData = [];
                for (const item of data) {
                    const loc = this.relposToLatLng(item.x, item.y);
                    heatmapData.push([loc.lat, loc.lng, item.rating]);
                }
                L.heatLayer(heatmapData, { minOpacity: 0.80, blur: 30, radius: 30 }).addTo(this.map);

                importCallback.apply(this, [btnELement, workbook, destinationContainer, currentImageNumber, sheetType, postTitle]);
            },
            onMarkerAdded: function (marker, data) {
                const rrosePopup = new L.Rrose({ offset: new L.Point(0, -10), closeButton: false, autoPan: false });
                rrosePopup.setContent(data.index);

                marker.on("add", function (event) {
                    event.target.openPopup();
                });

                marker.bindPopup(rrosePopup);
            },
            viewDestination: destinationContainer
        });
        $(image).imgRating('clear');
        $(image).imgRating('import', ratings);
    }

    private createWorksheet (btnELement: HTMLButtonElement, workbook: Workbook, heatmapContainer: HTMLElement, ratings: any, imgDiv: HTMLDivElement, currentImageNumber: number, sheetsMaxCount: number, sheetTypeName: string, postTitle: string): void {
        // Create worksheet title
        const worksheetTitle = this.createWorksheetTitle(currentImageNumber, sheetsMaxCount, sheetTypeName, postTitle);

        // check worksheet
        if (workbook.getWorksheet(worksheetTitle)) {
            return;
        }

        // Create worksheet
        const worksheet = workbook.addWorksheet(worksheetTitle);

        // add rating to xlsx
        this.addRatingsToXlsx(worksheet, ratings);

        // convert heaatmap to canvas
        html2canvas(heatmapContainer).then(canvas => {
            this.canvasCounter++;

            // add canvas to xlsx
            this.addCanvasToXlsx(workbook, worksheet, canvas);

            // check heatmaps count
            if (this.canvasCounter === (sheetsMaxCount * 3)) {
                // Uložení sešitu
                this.writeXlsx(workbook, imgDiv);
                // Remove button spinner
                this.removeSpinner(btnELement);
            }
        });
    }

    private createWorksheetTitle (currentImageNumber: number, sheetsMaxCount: number, sheetTypeName: string, postTitle: string): string {
        let preAndPostTitleLength = (String((sheetsMaxCount > 1 ? currentImageNumber + '. ' : '') + ' ' + sheetTypeName)).trim().length + 1;
        let sheetTitleBase = postTitle;
        if (postTitle.length > this.sheetNameMaxLength) {
            sheetTitleBase = postTitle.substring(0, this.sheetNameMaxLength - preAndPostTitleLength).trim();
        }

        return (sheetTypeName + ' ' + (sheetsMaxCount > 1 ? currentImageNumber + '. ' : '') + sheetTitleBase).trim();
    }

    private getRatings (ratingsToFilter, ratingType) {
        return ratingsToFilter.filter(function (rating) {
            if (ratingType === 'negativ') {
                return rating.rating < 0;
            } else if (ratingType === 'positiv') {
                return rating.rating > 0;
            } else if (ratingType === 'neutral') {
                return rating.rating === 0;
            }
        });
    }

    private onLoadBackgroundImage (btnELement: HTMLButtonElement, workbook: Workbook, imgDiv: HTMLDivElement, imgClone: HTMLElement, currentImageNumber: number, sheetsMaxCount: number, divOptions: any, postTitle: string): void {
        const that = this;

        // Prochazeni sheetu
        for (const sheetType in that.sheets) {
            const sheetTypeName = that.sheets[sheetType];
            // get ratings by sheet
            const ratings = that.getRatings(divOptions.ratings, sheetTypeName);

            // sort ratings points
            ratings.sort(function (a, b) {
                if (a.y < b.y) {
                    return -1;
                } else if (b.y < a.y) {
                    return 1;
                } else {
                    return 0;
                }
            });

            // add index info
            for (const i in ratings) {
                ratings[i].index = parseInt(i) + 1;
            }

            // Create heatmap on image
            that.createImgRating(btnELement, workbook, imgClone, ratings, imgDiv, currentImageNumber, sheetTypeName, postTitle, function (btnELement: HTMLButtonElement, workbook: Workbook, imgDivContainer: HTMLDivElement, imageNumber: number, sheetTypeName: string, postTitle: string) {
                // Get final container
                const heatmapContainer = this.map.getContainer();

                that.createWorksheet(btnELement, workbook, heatmapContainer, ratings, imgDivContainer, imageNumber, sheetsMaxCount, sheetTypeName, postTitle);
            });
        }
    }

    private processImagesBaseDivs (btnElement: HTMLButtonElement, workbook: Workbook, baseDiv: HTMLElement, currentImageNumber: number): Promise<HTMLImageCloneElement> {
        // Get options
        const divOptions = $(baseDiv).data('options');

        const postTitle = $(baseDiv).data('title').replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ' ');

        // Get base rated image
        const $jQueryBckImg = $(baseDiv).find('img[data-image]');

        return this.processRatedImage(btnElement, workbook, <HTMLImageElement> $jQueryBckImg[0], divOptions, postTitle, currentImageNumber);
    }

    private processRatedImage (btnElement: HTMLButtonElement, workbook: Workbook, ratedImage: HTMLImageElement, divOptions: any, postTitle: any, currentImageNumber: number): Promise<HTMLImageCloneElement> {
        // Copy heatmap image (we don't want to create heatmap on the original img element - it'll be rewrote ;-) & we can hide this container)
        const imgDiv = document.createElement('div');
        imgDiv.setAttribute('class', 'heatmap-img-copy');
        imgDiv.style.position = 'absolute';
        imgDiv.style.left = '-5000px';
        imgDiv.style.top = '-5000px';
        const imgClone = <HTMLImageCloneElement> document.createElement('img');
        $(imgClone).appendTo(imgDiv);
        $(imgDiv).appendTo('body');

        // save data for local use
        imgClone.dataBtnElement = btnElement;
        imgClone.dataWorkbook = workbook;
        imgClone.dataImgDiv = imgDiv;
        imgClone.dataImgClone = imgClone;
        imgClone.dataDivOptions = divOptions;
        imgClone.dataPostTitle = postTitle;
        imgClone.dataImageNumber = currentImageNumber;


        imgClone.onload = null; // remove action - leaflet calls this event
        return new Promise(resolve=>{
            imgClone.addEventListener('load',()=> {
                resolve(imgClone)
            })
            imgClone.src = ratedImage.src;
        })
    }

    private removeSpinner (btnELement): void {
        btnELement.innerHTML = btnELement.innerText.trim();
    }

    private writeXlsx (workbook, htmlContainer): void {
        workbook.xlsx.writeBuffer()
            .then(buffer => {
                // Vytvoření Blob objektu z bufferu
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Použití FileSaver k uložení souboru a nabídnutí ke stažení
                saveAs(blob, 'export.xlsx');

                this.clear(htmlContainer);
            })
            .catch(error => {
                console.error('Chyba při vytváření sešitu:', error);
            });
    }
}
