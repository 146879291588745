// TODO: nefunguje zobrazování/skrývání zrušení filtru, možná extenze, či o úroveň výš; používá se na více místech (přehled e deníčků, výběr projektu, aktivita uživatelů, přehld odměn, zákaznická cesta, aj.)
import BaseComponent from '../../BaseComponent';

export default class FilterContainer extends BaseComponent {
    init () {
        this.$component.find('[data-filter-component]').each((i, el) => this.initFilterControls($(el)));

        this.$component.on('click', '[data-remove-filter]', (e: JQuery.ClickEvent<HTMLElement, undefined, HTMLElement>) => {
            this.resetAllInputs(this.getClosestFilterComponent(e.currentTarget));
        });

        this.$component.on('click', '[data-remove-filter-no-submit]', (e: JQuery.ClickEvent<HTMLElement, undefined, HTMLElement>) => {
            e.preventDefault();
            this.resetAllInputs(this.getClosestFilterComponent(e.currentTarget));
        });

        this.$component.find('[data-filter-component="period"]').on('change', 'input', (e: JQuery.ChangeEvent<HTMLElement, undefined, HTMLInputElement>) => {
            const $periodFilter = $(e.currentTarget).closest('[data-filter-component]');

            if ($(e.target).is(':checkbox')) {
                this.resetOtherCheckboxes($periodFilter, e.target);
            } else {
                this.selectOtherChoice($periodFilter);
            }
        });

        this.$component.on('change', (e, $filterComponent = null) => {
            e.preventDefault();

            if (!$filterComponent) {
                $filterComponent = this.getClosestFilterComponent(e.target);
            }

            this.initFilterControls($filterComponent);
        });

        const $searchInput = this.$component.find('input[data-target-list-filter]');
        if ($searchInput.length > 0) {
            const targetListSelector = $searchInput.data('target-list-filter');
            if (targetListSelector && targetListSelector !== '') {
                const $filterList = this.$component.find(targetListSelector);

                $searchInput.on('input', (e) => {
                    const query = (<HTMLInputElement>e.currentTarget).value.replace(/s/g, '').toUpperCase();
                    $filterList.each((i, filteredItem) => {
                        const $filteredItem = $(filteredItem);
                        const text = $filteredItem.text().toUpperCase();
                        const $parent = $filteredItem.closest('div');
                        if (text.includes(query)) {
                            $parent.show();
                        } else {
                            $parent.hide();
                        }
                    });

                    // activate form controls
                    const $filterComponent = this.getClosestFilterComponent(e.target);
                    if ($filterComponent) {
                        this.initFilterControls($filterComponent);
                    }
                });
            }
        }

        this.$component.find('.selection-group').on('click', e => e.stopPropagation());
    }

    private getClosestFilterComponent (input): JQuery<HTMLElement> {
        return $(input).closest('[data-filter-component]');
    }

    private initFilterControls ($filterComponent): void	{
        const $controls = $filterComponent.find('[data-dialog-controls]');

        if (this.isActiveFilter($filterComponent)) {
            $controls.show();
        } else {
            $controls.hide();
        }
    }

    private isActiveFilter ($filterComponent): boolean {
        let isActive = false;

        $filterComponent.find('input:not(:checkbox), select').each((i, el) => {
            if (!isActive && $(el).val() !== '') {
                isActive = true;
            }
        });

        $filterComponent.find('input:checkbox').each((i, checkbox) => {
            if (!isActive && $(checkbox).prop('checked') === true) {
                isActive = true;
            }
        });

        $filterComponent.find('input[data-target-list-filter]').each((i, input) => {
            if (!isActive && $(input).val() !== '') {
                isActive = true;
            }
        });

        return isActive;
    }

    private resetAllInputs ($filterComponent): void {
        $filterComponent.find('input:not([type=checkbox]):not([type=radio]), select').val('');
        $filterComponent.find('input[type=radio], input[type=checkbox]').prop('checked', false);
        $filterComponent.find('select.selectize').each((i, select) => select.selectize?.clear());
        if (this.$component.is('form')) {
            this.$component.trigger('change', [$filterComponent]);
        } else {
            // work-around for datagrid outer-filter
            this.$component.find('input:first').trigger('change');
        }
    }

    private selectOtherChoice ($periodFilter): void {
        $periodFilter.find('input:checkbox').prop('checked', false);
    }

    private resetOtherCheckboxes ($periodFilter, clickedCheckbox): void {
        $periodFilter.find('input.date').val('');
        $periodFilter.find(':checkbox').not(clickedCheckbox).prop('checked', false);
    }
}
