import BaseComponent from '../../BaseComponent';

export default class FileInput extends BaseComponent {
    private $fileInput: JQuery<HTMLInputElement>
    private $resetButton: JQuery<HTMLElement>

    public init (): void {
        this.$fileInput = <JQuery<HTMLInputElement>> this.$component.find('#filesInput');
        this.$resetButton = <JQuery<HTMLInputElement>> this.$component.find('#resetFiles');
            this.$fileInput.on('change', () => {
                const hasFiles = this.$fileInput[0].files && this.$fileInput[0].files.length > 0;
                if (hasFiles) {
                    this.$resetButton.removeClass('d-none');
                } else {
                    this.$resetButton.addClass('d-none');
                }

                this.$resetButton.on('click', (e) => this.resetFileInput(e));

            });
    }

    private resetFileInput(e): void {
        e.preventDefault();
        this.$fileInput.val('');
        this.$resetButton.addClass('d-none');
    }
}
